export const initialState = {
	id: 0,
	name: '',
	theme: 'light',
	spellChecking: 'browser',
	langTool: 'off',
	minMatchRateTM: 60,
	hideMatchesMT: false,
	autoInsertFirstTMSuggestion: false,
	commentsNotifyWhenTaskComplete: false,
	sourceDifferenceDisabled: false,
	doubleQuotes: "0022",
	singleQuotes: "0027",
	customTranslationColors: false,
	goToUnconfirmed: true,
	fuzzyMatch103: "#0a100c",
	fuzzyMatch102: "#254719",
	fuzzyMatch101: "#1f8237",
	fuzzyMatch100: "#32c04d",
	fuzzyMatch95to99: "#f8651c",
	fuzzyMatch85to94: "#f87625",
	fuzzyMatch75to84: "#f88c39",
	fuzzyMatch50to74: "#f99d5b",
	fuzzyMatchMT: "#80a5d1",
	fuzzyMatchTB: "#b231bd",
	interfaceSize: 100,
	showAllHistory: false,
	tasks: {},
	supportedLangsSpellcheck: {}
};

const UserReducer = (state = initialState, action) => {
	const { payload, type } = action;

	switch (type) {
		case 'user/setProps':
			window &&
				window.localStorage.setItem(
					'userPreferences',
					JSON.stringify({
						...state,
						...payload,
					})
				);

			return {
				...state,
				...payload,
			};
		default:
			return state;
	}
};

export default UserReducer;
